/* eslint-disable camelcase */
import { Image, PrismicImage, transformToImage } from './Image';

export interface MetaDataResource {
    meta_title?: string;
    meta_description?: string;
    meta_image?: PrismicImage;
}

export interface MetaData {
    title?: string;
    description?: string;
    image?: Image;
}

export const transformToMetaData = (
    metaDataResource: MetaDataResource,
): MetaData => {
    const title = metaDataResource.meta_title || undefined;
    const description = metaDataResource.meta_description || undefined;
    const image = metaDataResource.meta_image
        ? transformToImage(metaDataResource.meta_image)
        : undefined;

    return { title, description, image };
};
